import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

function TextWithTick({ color, text }) {
  return (
    <div className="flex items-center">
      <FontAwesomeIcon
        icon={faCheckCircle}
        color="#009378"
        className="mr-2 h-4 w-4"
      />
      <p className="text-xl font-bold">{text}</p>
    </div>
  );
}

export default TextWithTick;
