import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import TestimonialsCard from '../TestimonialsCard/index';
import { EffectCoverflow, Pagination, Navigation } from 'swiper';
import './styles.css';
import useResizer from '@components/extra/useResizer';
import TestimonialsCardMobile from '../TestimonialsCardMobile/index';

export default function TestimonialsSection({ testimonials }) {
  const middleIndex = Math.floor(testimonials.length / 2);
  const isScreenLessThan1200 = useResizer(1200);

  return (
    <div className="w-[80%] md:w-[80%] ">
      <div className="testimonial-swiper-container swiper_container relative flex items-center">
        <Swiper
          effect={'coverflow'}
          grabCursor={true}
          centeredSlides={true}
          loop={true}
          slidesPerView={isScreenLessThan1200 ? 1 : 'auto'}
          spaceBetween={isScreenLessThan1200 ? 0 : -100}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 50,
            modifier: 1,
          }}
          pagination={{ el: '.swiper-pagination', clickable: true }}
          navigation={{
            nextEl: '.testimonial-swiper-container .swiper-button-next',
            prevEl: '.testimonial-swiper-container .swiper-button-prev',
            clickable: true,
          }}
          modules={[EffectCoverflow, Pagination, Navigation]}
          initialSlide={middleIndex}
          className=""
        >
          {testimonials.map((testimonial) => (
            <SwiperSlide key={testimonial.id}>
              <TestimonialsCard testimonial={testimonial} />
              <TestimonialsCardMobile testimonial={testimonial} />
            </SwiperSlide>
          ))}

          <div className="slider-controler hidden md:flex">
            <div className="swiper-pagination"></div>
          </div>
        </Swiper>
        <div className="swiper-button-prev slider-arrow absolute -left-[40px] md:-left-[100px]"></div>
        <div className="swiper-button-next slider-arrow absolute -right-[40px] md:-right-[100px]"></div>
      </div>
    </div>
  );
}
