import React from 'react';
import TextWithTick from '@components/HireFromCrio/HeroSection/TextWithTick';
import HireFromCrioForm from '@components/HireFromCrio/HireFromCrioForm/HireFromCrioForm';

export default function HeroSection() {
  return (
    <section className="flex w-full items-center justify-center bg-crio-neutral-900 pt-24  md:h-[740px]">
      <div className="flex h-full w-11/12 flex-col justify-center px-4 text-white md:flex-row md:px-4 xl:px-10">
        <div className="mb-14 flex h-full w-full flex-col  text-center md:w-1/2 md:text-left">
          <h1
            className={`break-keep mb-4 font-dmSans text-6xl font-bold md:w-full md:text-7xl xl:text-8xl`}
            style={{ lineHeight: '1.2' }}
          >
            Hire skilled developers
          </h1>
          <h2 className="mb-4 text-xl font-normal leading-loose">
            Crio helps developers learn <strong>job-ready tech skills</strong>{' '}
            by <strong>building real software projects</strong>. Developers who
            complete our <strong>revolutionary project-based curriculum</strong>{' '}
            are ready to help your company reach its highest-impact goals.
          </h2>
          <div className="">
            <ul className="list-none pl-0">
              <li>
                <TextWithTick text="MERN Stack Developers" />
              </li>
              <li>
                <TextWithTick text="Java Springboot Developers" />
              </li>
              <li>
                <TextWithTick text="QA Automation Engineers" />
              </li>
            </ul>
          </div>
        </div>
        <div className="flex h-full w-full justify-center md:w-1/2 ">
          <div className="w-full md:w-4/5">
            <HireFromCrioForm />
          </div>
        </div>
      </div>
    </section>
  );
}
