import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import useResizer from '@components/extra/useResizer';

export default function TestimonialsCard({ testimonial }) {
  const {
    employeeImage,
    employeeName,
    employeeDesignation,
    employeeTestimonial,
    companyImage,
  } = testimonial;
  const isScreenLessThan1200 = useResizer(1200);
  const [isReadMoreEnabled, setIsReadMoreEnabled] = React.useState(false);

  // const imagePath = isCompany
  //   ? 'images/fellowship/IndustryTestimonial/'
  //   : 'images/strapi/crio-employee-testimonials/';

  const data = useStaticQuery(graphql`
    query {
      TestimonialImages: allFile(
        filter: {
          relativePath: { regex: "images/fellowship/IndustryTestimonial/" }
        }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              width: 800
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  `);

  const testimonialImageNodes = data?.TestimonialImages?.nodes;

  const testimonialImage = testimonialImageNodes.find((node) =>
    node.name.includes(employeeImage),
  );

  const compImage = testimonialImageNodes.find((node) =>
    node.name.includes(companyImage),
  );

  const testimonialGatsbyImage = getImage(testimonialImage?.childImageSharp);
  const companyGatsbyImage = getImage(compImage?.childImageSharp);

  return (
    <div
      className="hidden grid-cols-[1fr,2fr] gap-14 rounded-[20px] border border-[#E6F4F2] bg-white py-6 px-5 md:grid"
      style={{
        boxShadow: isScreenLessThan1200
          ? 'none'
          : '10px 10px 10px 0px #0000000D',
      }}
    >
      {/* Employee Image */}
      <div className="my-auto h-[310px] w-[250px] overflow-hidden rounded-[20px]">
        <GatsbyImage
          image={testimonialGatsbyImage}
          className="h-full w-full object-cover"
          alt={employeeName}
        />
      </div>

      <div className="h-[330px] overflow-scroll py-3 font-manrope">
        {/* Employee Details */}
        <div className="mb-2 font-light text-v5-neutral-500 md:text-left md:text-[22px] md:leading-[32px]">
          <strong className="font-extrabold">{employeeName}</strong>
        </div>
        <div className="mb-7 flex items-center ">
          <div className="text-left font-rubik font-semibold text-v5-neutral-400 md:text-base md:leading-none">
            {employeeDesignation}
          </div>
          <GatsbyImage
            image={companyGatsbyImage}
            className="mx-2 h-full w-28 object-contain"
            alt={'company logo'}
          />
        </div>
        <div className="mb-3 h-1 w-full bg-[#DAF1EA]" />

        {/*  Employee Testimonial */}
        <div className=" text-left font-normal text-v5-neutral-400 md:text-base">
          {employeeTestimonial.length > 170 ? (
            <>
              {isReadMoreEnabled ? (
                employeeTestimonial
              ) : (
                <>
                  {employeeTestimonial.substring(0, 200)}...{' '}
                  <span
                    className="cursor-pointer text-[14px] font-semibold leading-[24px] text-v5-green-300 underline"
                    onClick={() => setIsReadMoreEnabled(true)}
                  >
                    read more
                  </span>
                </>
              )}
            </>
          ) : (
            employeeTestimonial
          )}
        </div>
      </div>
    </div>
  );
}
