import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import TechList from '../TechList';
import RawHTML from '@components/Common/RawHTML/index';

export default function SkillCard({
  title,
  description,
  imgName,
  skillsData,
  reverse,
  index,
}) {
  const image = getImage(imgName.childImageSharp);

  // Query to get the images for Skill Section badge
  const data = useStaticQuery(graphql`
    query {
      badgeImages: allFile(
        filter: { relativePath: { regex: "images/recruit/Badges/" } }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              blurredOptions: {}

              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  `);

  const badgeImageNodes = data?.badgeImages?.nodes;

  const CrioCertifiedBadgeImageNode = badgeImageNodes.find((node) =>
    node.name.includes('Crio-Certified-Badge'),
  );
  const hiringBadgeNode = badgeImageNodes.find((node) =>
    node.name.includes('HiringFee-Badge-Green'),
  );

  const certifiedImage = getImage(CrioCertifiedBadgeImageNode?.childImageSharp);
  const hiringImage = getImage(hiringBadgeNode?.childImageSharp);

  return (
    <div
      className={`relative flex flex-col items-center justify-center rounded-xl bg-crio-neutral-75 p-6 md:flex-row ${
        reverse ? 'md:flex-row-reverse' : ''
      } md:items-start md:rounded-3xl md:px-8 md:py-12 md:text-left`}
      style={{
        boxShadow: '4px 4px 15px #FFFF, inset 4px 4px 5px rgba(0, 0, 0, 0.15)',
      }}
    >
      {/* BADGE Starts */}
      {index === 0 && (
        <>
          <div
            className="absolute hidden w-36 rotate-12 md:block"
            style={{ zIndex: '2', top: '-70px', right: '-75px' }}
          >
            <GatsbyImage
              loading="lazy"
              image={certifiedImage}
              placeholder="blurred"
              alt="Certified Badge"
              className="w-full"
              imgStyle={{ objectFit: 'contain' }}
            />
          </div>
          <div className="z-2 absolute -top-7 -left-7 block w-24 -rotate-12 md:hidden">
            <GatsbyImage
              loading="lazy"
              image={certifiedImage}
              placeholder="blurred"
              alt="Crio Certified Badge"
              className="w-full"
              imgStyle={{ objectFit: 'contain' }}
            />
          </div>
        </>
      )}

      {/* BADGE ENDS */}

      {/* HIRING BADGE Starts */}
      {index == 2 && (
        <div
          className="absolute bottom-[-50px] right-2 block w-20 rotate-12 md:right-1/3 md:bottom-[-65px] "
          style={{ zIndex: '2' }}
        >
          <GatsbyImage
            loading="lazy"
            image={hiringImage}
            placeholder="blurred"
            alt="Hiring Fee Badge White"
            className="w-[86px] w-full md:w-[138px]"
            imgStyle={{ objectFit: 'contain' }}
          />
        </div>
      )}
      {/* HIRING BADGE Ends */}

      <div className="flex h-full w-4/5 items-center justify-center md:w-2/5">
        <div className="h-full w-4/5">
          <GatsbyImage
            loading="lazy"
            image={image}
            placeholder="blurred"
            alt={title}
            style={{ maxHeight: index !== 0 ? '231px' : '' }}
            className="h-full w-full"
            imgStyle={{ objectFit: 'contain' }}
          />
        </div>
      </div>
      <div className="flex h-full w-full flex-col md:w-3/5">
        <h2 className="my-4 text-xl font-medium text-crio-neutral-900 md:text-2xl">
          {title}
        </h2>
        <RawHTML className="leading-loose text-crio-neutral-800">
          {description}
        </RawHTML>
        {skillsData && (
          <div className="hidden w-4/5 md:block">
            <div
              className="my-6"
              style={{
                background: 'linear-gradient(0deg, #F3F1EE, #F3F1EE), #CFCDC1',
                boxShadow:
                  '-3px -3px 5px rgba(243, 241, 238, 0.5), inset 2px 2px 4px rgba(0, 0, 0, 0.25)',
                borderRadius: '5.5px',
                height: '5px',
                width: '100%',
              }}
            ></div>
            <div className="flex gap-4">
              <TechList
                title="Full-Stack Developers"
                skills={skillsData.frontendSkills}
              />
              <TechList
                title="Backend Developers"
                skills={skillsData.backendSkills}
              />
              <TechList title="QA Automation" skills={skillsData.QASkills} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
