import React from 'react';
import FAQs from '@components/Common/FAQs';
import hireFromCrioData from '@src/PageData/hireFromCrio.json';
import RequestCallbackButton from '../RequestCallbackButton/index';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

export default function FAQSection() {
  const data = useStaticQuery(graphql`
    query {
      recruitImages: allFile(
        filter: { relativePath: { regex: "images/recruit/" } }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              blurredOptions: {}

              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
      badgeImages: allFile(
        filter: { relativePath: { regex: "images/recruit/Badges/" } }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              blurredOptions: {}

              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  `);

  const recruitImageNodes = data?.recruitImages?.nodes;
  const badgeImageNodes = data?.badgeImages?.nodes;
  const testimonialImage = recruitImageNodes.find((node) =>
    node.name.includes('End-CTA'),
  );
  const testimonialBadgeImage = badgeImageNodes.find((node) =>
    node.name.includes('HiringFee-Badge-White'),
  );

  const endCTAImg = getImage(testimonialImage?.childImageSharp);
  const badgeImg = getImage(testimonialBadgeImage?.childImageSharp);

  return (
    <section className="bg-crio-neutral-100">
      <div className="pb-32 pt-16">
        <div className="container text-center text-crio-green-700">
          <h2 className="mb-16 text-3xl font-bold">FAQs</h2>
          <div className="mb-48 text-crio-neutral-800">
            <FAQs faqData={hireFromCrioData?.faqData}></FAQs>
          </div>
        </div>
      </div>
      <section className="-mb-10 mt-36 flex justify-center bg-crio-neutral-two-100 md:mt-28">
        <div
          style={{
            background:
              'linear-gradient(106.74deg, #2F2F2F 0.65%, #232323 106.18%)',
            boxShadow:
              '4px 4px 15px rgba(63, 63, 63, 0.3), inset 4px 4px 5px rgba(0, 0, 0, 0.15)',
            borderRadius: '50px',
            zIndex: '2',
            transform: 'translateY(-30%)',
          }}
          className="relative w-11/12 md:w-4/5 md:px-8"
        >
          <div
            className="absolute block w-24 rotate-12 md:hidden"
            style={{ zIndex: '2', bottom: '150px', right: '10px' }}
          >
            <GatsbyImage
              loading="lazy"
              image={badgeImg}
              placeholder="blurred"
              alt="Hiring Fee Badge White"
              className="w-full"
              imgStyle={{ objectFit: 'contain' }}
            />
          </div>
          <div
            className="flex flex-col items-center justify-center gap-4 px-8 py-10"
            style={{
              marginTop: '-100px',
            }}
          >
            <div className="relative -mt-28 block h-1/5 w-11/12 md:-mt-48 md:w-2/5 md:max-w-lg">
              <GatsbyImage
                loading="lazy"
                image={endCTAImg}
                placeholder="blurred"
                alt="End CTA"
                className="h-full w-full"
                imgStyle={{ objectFit: 'contain' }}
              />
            </div>
            <h2 className="pb-5 text-center text-4xl font-bold text-white md:w-3/5 md:text-7xl">
              Hire Skilled Software Developers
            </h2>
            <div className="my-4 flex w-full items-center justify-center md:justify-start">
              <div className="flex w-1/2 flex-col items-center justify-center md:w-full">
                <div className="relative">
                  <div
                    className="absolute hidden w-24 rotate-12 md:block"
                    style={{
                      zIndex: '2',
                      bottom: '-10px',
                      right: '-100px',
                    }}
                  >
                    <GatsbyImage
                      loading="lazy"
                      image={badgeImg}
                      placeholder="blurred"
                      alt="Hiring Fee Badge White"
                      className="w-full"
                      imgStyle={{ objectFit: 'contain' }}
                    />
                  </div>
                  <RequestCallbackButton scrollToTop={true} />
                </div>
                <label className="mt-2 hidden text-white md:block">
                  Submit your requirements & our team will get in touch
                </label>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}
