import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import RawHTML from '@components/Common/RawHTML';
import hireFromCrioData from '@src/PageData/hireFromCrio.json';
import RequestCallbackButton from '../RequestCallbackButton/index';

export default function WhyHireSection() {
  // Query to get the images for the Why Hire section
  const data = useStaticQuery(graphql`
    query {
      learningDataImages: allFile(
        filter: { relativePath: { regex: "images/recruit/Why/" } }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              blurredOptions: {}
              width: 800
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
      badgeImages: allFile(
        filter: { relativePath: { regex: "images/recruit/Badges/" } }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              blurredOptions: {}

              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  `);

  const learningDataImagesData = data.learningDataImages.nodes;
  const badgeImageNodes = data?.badgeImages?.nodes;
  const testimonialBadgeImage = badgeImageNodes.find((node) =>
    node.name.includes('HiringFee-Badge-White'),
  );
  const badgeImg = getImage(testimonialBadgeImage?.childImageSharp);

  return (
    <section className="flex w-full items-center justify-center bg-crio-neutral-two-100 p-10 pt-14 text-center text-white">
      <div className="relative h-full w-full">
        {/* HIRING BADGE Starts */}
        <div
          className="absolute top-[-80px] block w-20 -rotate-12 md:top-[-225px] md:left-[60px] md:rotate-12 "
          style={{ zIndex: '2' }}
        >
          <GatsbyImage
            loading="lazy"
            image={badgeImg}
            placeholder="blurred"
            alt="Hiring Fee Badge White"
            className="w-[86px] w-full md:w-[138px]"
            imgStyle={{ objectFit: 'contain' }}
          />
        </div>

        {/* HIRING BADGE Ends */}
        <h1 className={`font-dmSans text-3xl`}>Why hire a</h1>
        <h1
          className={`bg-clip-text font-dmSans text-6xl text-transparent`}
          style={{
            background:
              'linear-gradient(268.38deg, #45B680 11.19%, #A7DF67 44.32%)',
          }}
        >
          Crio Certified Developer?
        </h1>
        <div className="my-12 flex justify-center">
          <div className="flex w-full flex-col gap-4 md:w-4/5 md:flex-row md:gap-12">
            {hireFromCrioData.learningData.map((learning) => {
              const learningImage = learningDataImagesData.find(
                (ele) => ele.name === learning.imgUrl,
              );
              const image = getImage(learningImage.childImageSharp);
              return (
                <div
                  className="flex flex-col items-center justify-start bg-crio-neutral-900 p-6 md:w-1/3 md:items-start md:px-8 md:py-12 md:text-left"
                  style={{
                    boxShadow:
                      '4px 4px 15px rgba(63, 63, 63, 0.3), inset 4px 4px 5px rgba(0, 0, 0, 0.15)',
                    borderRadius: '15px',
                  }}
                  key={learning.title}
                >
                  <div className="w-40">
                    <GatsbyImage
                      loading="lazy"
                      image={image}
                      alt={'Learning'}
                      placeholder="blurred"
                      imgStyle={{ objectFit: 'contain' }}
                      className="h-full w-full"
                    />
                  </div>
                  <h2
                    className={`text-2=3xl my-2 font-dmSans font-medium text-crio-green-two-50`}
                  >
                    {learning.title}
                  </h2>
                  <p className="text-xl leading-loose text-crio-neutral-two-20">
                    <RawHTML>{learning.description}</RawHTML>
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="my-4 flex w-full items-center justify-center md:justify-start">
          <div className="flex w-1/2 flex-col items-center justify-center md:w-full">
            <RequestCallbackButton scrollToTop={true} />
            {/* <ApplyNowCTA
              type="RECRUIT_PAGE"
              link="PVS4Mz"
              location="RECRUIT_PAGE"
              text="Start Hiring"
              allowMultipleFill
              noEmail
              className="shimmer-btn w-full bg-crio-yellow-500  p-4  text-crio-green-900"
              applyNowInsideClassName="shimmer-btn bg-crio-yellow-500 text-crio-green-900  p-4"
              openEvent="PROGRAM_ME_APPLY_NOW_CLICKED"
              closeEvent="PROGRAM_ME_EMAIL_FORM_CLOSED"
            ></ApplyNowCTA> */}
            <label className="mt-2 text-crio-neutral-75">
              Submit your requirements & our team will get in touch
            </label>
          </div>
        </div>
      </div>
    </section>
  );
}
