import React, { useContext, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faPhone,
  faUser,
  faQuestionCircle,
  faIdBadge,
  faBriefcase,
} from '@fortawesome/free-solid-svg-icons';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import FormInput from '../FormComponents/FormInput';
import FormPhoneInput from '../FormComponents/FormPhoneInput';
import FormDropDown from '../FormComponents/FormDropDown';
import toast from 'react-hot-toast';
import RequestCallbackButton from '../RequestCallbackButton/index';
import Button from '@components/Button/index';
import { roleStatusMenuItems } from '@src/constants/HireFromCrio/index';
import { prepareUtmParams, schema } from '@src/utils/HireFromCrio/index';
import { PrepareActivityCreationData } from '@src/utils/HireFromCrio/index';
import { isEmpty } from '@src/utils/HireFromCrio/index';
import { areAllFieldsFilled } from '@src/utils/HireFromCrio/index';
import { prepareCompanyData } from '@src/utils/HireFromCrio/index';
import { preparePocData } from '@src/utils/HireFromCrio/index';
import { handleCompanyLeadCreation } from '@src/utils/HireFromCrio/index';
import { handlePOCLeadCreation } from '@src/utils/HireFromCrio/index';
import { handleCreateActivityUponLeadCreation } from '@src/utils/HireFromCrio/index';
import { GTM } from '@src/analytics/gtm';
import { gtmEvents } from '@src/analytics/gtmEventsConstant';
import { GlobalStateContext } from '@src/context/GlobalContextProvider';

// Main form component
const HireFromCrioForm = () => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const globalState = useContext(GlobalStateContext);
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  //Fetch success popup image
  const imagesData = useStaticQuery(graphql`
    query {
      allImages: allFile(
        filter: { relativePath: { regex: "images/recruit/" } }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              blurredOptions: {}
              width: 800
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  `);

  const successImageNode = imagesData.allImages.nodes.find(
    (ele) => ele.name === 'successful_popup',
  );

  const successImage = getImage(successImageNode?.childImageSharp);
  const storedParams = globalState.utm;
  const searchParam = new URLSearchParams(storedParams);

  // Function to handle form submission
  const onSubmit = async (e) => {
    const data = methods.watch();
    methods.trigger();

    if (!areAllFieldsFilled(data) || !isEmpty(methods.formState.errors)) {
      toast.error('Please fill in all required fields correctly.');
      return;
    }

    if (data.roleStatus !== 'Recruiter') {
      GTM.track(gtmEvents.HIRE_FROM_CRIO_NO_REC_FORM_SUBMIT, {
        email: data.companyEmail,
        fullName: data.fullName,
        phone: data.phone,
        role: data.role,
        company: data.company,
        roleStatus: data.roleStatus,
        source: 'Landing_page',
      });

      toast.success('Form submitted successfully!');
      setSubmitted(true);
      setLoading(false);
      return;
    }

    setLoading(true);
    try {
      const utmParameters = prepareUtmParams(searchParam);

      const companyData = prepareCompanyData(data.company, utmParameters);

      const companyLead = await handleCompanyLeadCreation(companyData);

      const pocData = preparePocData(
        data,
        companyLead.prospectId,
        companyLead.existingOwnerEmailAddress,
        utmParameters,
      );

      await handlePOCLeadCreation(pocData);

      //Create an activity upon successful lead creation to B2B LSQ
      const activityData = PrepareActivityCreationData(
        data,
        companyLead.prospectId,
      );

      await handleCreateActivityUponLeadCreation(activityData);

      toast.success('Form submitted successfully!');
      setSubmitted(true);
    } catch (error) {
      toast.error('Failed to submit the form');
    } finally {
      setLoading(false);
    }
  };

  // Function to handle form reset
  const handleReset = () => {
    methods.reset();
    setSubmitted(false);
  };

  return (
    <div
      id="hireFromCrioForm"
      className="mb-16 flex flex-col items-center justify-center rounded-lg bg-[#525252] px-4 py-4 md:mb-6 md:max-h-[630px] md:min-w-[400px] md:px-10"
    >
      {!submitted ? (
        <>
          <p className="m-2 font-manrope text-xl font-bold">
            Please fill in the Form
          </p>
          <FormProvider {...methods}>
            <div
              autoComplete="off"
              className="my-4 flex w-full flex-col items-stretch gap-3 overflow-y-auto md:my-4"
            >
              <FormInput
                icon={<FontAwesomeIcon icon={faIdBadge} />}
                label="Full name"
                name="fullName"
                error={methods.formState.errors?.fullName?.message}
              />
              <FormPhoneInput
                icon={<FontAwesomeIcon icon={faPhone} />}
                label="Phone"
                name="phone"
                error={methods.formState.errors?.phone?.message}
                validation={
                  methods.formState.dirtyFields?.phone &&
                  !methods.formState.errors?.phone
                }
              />
              <FormInput
                icon={<FontAwesomeIcon icon={faEnvelope} />}
                label="Company email"
                name="companyEmail"
                error={methods.formState.errors?.companyEmail?.message}
              />
              <FormInput
                icon={<FontAwesomeIcon icon={faBriefcase} />}
                label="Company"
                name="company"
                error={methods.formState.errors?.company?.message}
              />
              <FormInput
                icon={<FontAwesomeIcon icon={faUser} />}
                label="Your Role/Designation"
                name="role"
                error={methods.formState.errors?.role?.message}
              />
              <FormDropDown
                icon={<FontAwesomeIcon icon={faQuestionCircle} />}
                label="What defines you best?"
                menu_items={roleStatusMenuItems}
                name="roleStatus"
                error={methods.formState.errors?.roleStatus?.message}
              />
              <div className="flex w-full flex-col items-center justify-center md:w-full">
                <RequestCallbackButton
                  className="mx-auto mt-4"
                  onClick={(e) => onSubmit(e)}
                  loading={loading}
                />
              </div>
            </div>
          </FormProvider>
        </>
      ) : (
        <>
          <div className="text-center ">
            <GatsbyImage
              image={successImage}
              alt="Successful submission"
              className="mt-8 md:w-1/2"
              imgStyle={{ objectFit: 'contain' }}
            />
            <p className="m-2 font-manrope text-lg font-bold">
              Thank you for requesting a callback.
            </p>
            <Button
              onClick={handleReset}
              className="mx-auto mt-8 mb-8 rounded-lg border-2 border-solid border-white bg-transparent px-4 py-2 text-white hover:bg-crio-neutral-100 hover:text-crio-neutral-800"
            >
              Submit another response{' '}
              <span className="ml-2 font-semibold">{'>'}</span>
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default HireFromCrioForm;
