import React from 'react';
import SEO from '@components/Common/SEO/index';
import * as styles from './Recruit.module.scss';
import FooterV2 from '@components/FooterV2/index';
import Navigation from '@components/Navigation';
import HeroSection from '@components/HireFromCrio/HeroSection/index';
import WhyHireSection from '@components/HireFromCrio/WhyHireSection/index';
import SkillsSection from '@components/HireFromCrio/SkillsSection/index';
import CompaniesSection from '@components/HireFromCrio/CompaniesSection/index';
import CompanyTestimonialsSection from '@components/HireFromCrio/CompanyTestimonials/index';
import FAQSection from '@components/HireFromCrio/FAQSection/index';

export default function Recruit(props) {
  return (
    <div id="hireFromCrio" className={styles.root}>
      <SEO
        canonicalUrl={'https://crio.do/recruit'}
        title={
          'Hire Full Stack or Backend Developers with verified work experience'
        }
        description={
          'Hire skilled software developers with verified product building experience using MERN, Java Spring boot and more. Hire developers with dynamic problem solving skills, ready to help your company grow.'
        }
      ></SEO>

      <Navigation hideCTA hideTopStrip={true} />

      {/* Hero Section  */}
      <HeroSection />

      {/* Why Hire Section  */}
      <WhyHireSection />

      {/* SKILLS SECTION */}
      <SkillsSection />

      {/* COMPANIES SECTION */}
      <CompaniesSection />

      {/* TESTIMONIAL SECTION */}
      <CompanyTestimonialsSection />

      {/* FAQ Section  */}
      <FAQSection />

      {/* <Footer></Footer> */}
      <FooterV2 />
    </div>
  );
}
