import * as yup from 'yup';
import { indianRegex } from '@src/constants/HireFromCrio/index';
import { ELeadSquaredActivityCode } from '@src/constants/leadsquaredActivityConstants/index';
import { format } from 'date-fns';
import {
  generateLead,
  retrieveLeadByEmail,
  createActivityUponLeadGen,
  retrieveLeadByPhone,
  updateLeadByLeadId,
} from '../../../utils/hire-from-crio-lead-gen';

// Validation schema using yup
export const schema = yup.object({
  fullName: yup.string().required('Full name is required'),
  phone: yup
    .string()
    .required('Phone is required')
    .matches(/^[0-9]+$/, 'Phone must contain only digits')
    .test(
      'Check Indian Number',
      'Must be a valid phone number',
      function (value) {
        if (!value) return false;
        if (value.startsWith('91')) {
          const contactWithoutCountryCode = value.substring(2);
          return indianRegex.test(contactWithoutCountryCode);
        }
        return value.length >= 8; // Minimum length for international numbers
      },
    ),
  companyEmail: yup
    .string()
    .email('Must be a valid email')
    .required('Email is required'),
  company: yup.string().required('Company name is required'),
  role: yup.string().required('Role is required'),
  roleStatus: yup.string().required('Role status is required'),
});

// Function to create email id from an input string
export const createEmailId = (inputString) => {
  const modifiedString = inputString.replace(/\s/g, '');
  return modifiedString + '@criodo.com';
};

// Function to convert form data to API format
export const convertToApiFormat = (data) => {
  return Object.keys(data).map((key) => ({
    Attribute: key,
    Value: data[key],
  }));
};

//Function to convert form data to API format required for activity creation
export const PrepareActivityCreationData = (data, companyProspectId) => {
  // Prepare initial structure
  const activityData = {
    RelatedProspectId: companyProspectId,
    ActivityEvent:
      ELeadSquaredActivityCode.FILLED_CALL_BACK_REQUEST_ON_HIRE_FROM_CRIO_PAGE,
    ActivityNote: 'Your Activity Note', // You can modify this as needed
    ProcessFilesAsync: true,
    ActivityDateTime: format(new Date(), 'yyyy-MM-dd HH:mm:ss'), // Ensure correct format
    Fields: [],
  };

  // Map the data fields to the required format
  const fieldsMapping = {
    fullName: 'mx_Custom_1',
    phone: 'mx_Custom_2',
    companyEmail: 'mx_Custom_3',
    role: 'mx_Custom_4',
  };

  Object.keys(fieldsMapping).forEach((key) => {
    if (data[key]) {
      activityData.Fields.push({
        SchemaName: fieldsMapping[key],
        Value: data[key],
      });
    }
  });

  return activityData;
};

//Function to check if object is empty or not
export const isEmpty = (obj) => {
  return Object.entries(obj).length === 0;
};

// Function to check if all required fields are filled
export const areAllFieldsFilled = (data) => {
  const requiredFields = [
    'fullName',
    'phone',
    'companyEmail',
    'company',
    'role',
    'roleStatus',
  ];
  for (const field of requiredFields) {
    if (!data[field]) {
      return false;
    }
  }
  return true;
};

// Function to prepare company data
export const prepareCompanyData = (company, utmParameters) => {
  return {
    FirstName: company,
    EmailAddress: createEmailId(company),
    mx_Entity_Type: 'Account',
    Source: 'Landing_Page',
    OwnerEmailAddress: 'criodo@leadsquared.com',
    ...utmParameters,
  };
};

// Function to prepare POC data
export const preparePocData = (
  data,
  companyProspectId,
  existingOwnerEmailAddress = '',
  utmParameters,
) => {
  return {
    FirstName: data.fullName,
    EmailAddress: data.companyEmail,
    Phone: data.phone,
    mx_Designation: data.role,
    mx_Entity_Type: 'POC',
    mx_Account_ID: companyProspectId,
    Source: 'Landing_Page',
    OwnerEmailAddress:
      existingOwnerEmailAddress?.length > 0
        ? existingOwnerEmailAddress
        : 'criodo@leadsquared.com',
    ...utmParameters,
  };
};

export const prepareUtmParams = (searchParam) => {
  const utmSource = searchParam.get('utm_source');
  const utmMedium = searchParam.get('utm_medium');
  const utmCampaign = searchParam.get('utm_campaign');

  return {
    ...(utmSource && { mx_UTM_Source: utmSource }),
    ...(utmMedium && { mx_UTM_Medium: utmMedium }),
    ...(utmCampaign && { mx_UTM_Campaign: utmCampaign }),
  };
};

// Function to handle company lead creation or retrieval
export const handleCompanyLeadCreation = async (data) => {
  try {
    const dataInAPIFormat = convertToApiFormat(data);

    const existingLead = await retrieveLeadByEmail(data.EmailAddress);

    if (existingLead?.length > 0) {
      return {
        prospectId: existingLead[0]?.ProspectID,
        existingOwnerEmailAddress: existingLead[0]?.OwnerIdEmailAddress,
      };
    } else {
      const newLead = await generateLead(dataInAPIFormat);
      return {
        prospectId: newLead?.Message?.Id,
        existingOwnerEmailAddress: '',
      };
    }
  } catch (error) {
    console.error('Error in handleCompanyLeadCreation:', error);
    throw new Error('Failed to handle Company lead creation or retrieval');
  }
};

// Function to handle poc lead creation
export const handlePOCLeadCreation = async (data) => {
  try {
    const dataInAPIFormat = convertToApiFormat(data);

    const isExistingLead = await retrieveLeadByPhone(data.Phone);

    if (isExistingLead?.length > 0) {
      const leadId = isExistingLead[0].ProspectID;

      const { Phone, EmailAddress, ...rest } = data;
      const updatedData = convertToApiFormat(rest);

      const updatedLead = await updateLeadByLeadId(leadId, updatedData);

      return { prospectId: leadId };
    } else {
      const newLead = await generateLead(dataInAPIFormat);
      return { prospectId: newLead?.Message?.Id };
    }
  } catch (error) {
    console.log(error);
    throw new Error('Failed to handle POC lead creation');
  }
};

//Function to handle activity creation upon successful lead creation
export const handleCreateActivityUponLeadCreation = async (data) => {
  try {
    const activity = await createActivityUponLeadGen(data);
  } catch (error) {
    console.log(error);
    throw new Error('Faild to create activity upon lead creation');
  }
};
