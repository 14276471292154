import TestimonialsV3 from '@components/TestimonialsV3/index';
import React from 'react';

export default function Testimonials({ data }) {
  const { header, sub, testimonials } = data;

  // testimonials data from strapi:
  // const {
  //   strapi_metadata: { crio_employee_testimonials },
  // } = db;

  function sanitizeTestimonialData() {
    return testimonials.map(
      ({ employeeName, role, description, imgUrl, companyImage }) => ({
        employeeName: employeeName,
        employeeDesignation: role,
        employeeImage: imgUrl,
        companyImage: companyImage,
        employeeTestimonial: description,
      }),
    );
  }

  const companyTestimonials = sanitizeTestimonialData();

  return (
    <TestimonialsV3
      header={header}
      sub={sub}
      testimonials={companyTestimonials}
      id="#testimonials"
    />
  );
}
