import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import SkillCard from './SkillCard';
import hireFromCrioData from '@src/PageData/hireFromCrio.json';
import RequestCallbackButton from '../RequestCallbackButton/index';

export default function SkillsSection() {
  const data = useStaticQuery(graphql`
    query {
      skillImages: allFile(
        filter: { relativePath: { regex: "images/recruit/Skills/" } }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              blurredOptions: {}
              width: 800
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  `);

  const skillImagesData = data.skillImages.nodes;

  const skillsData = [
    {
      title: 'Mastery in Full-Stack & Backend Development',
      description: `Crio developers learn the following technologies and concepts through multiple guided and independently built projects.`,
      imgName: skillImagesData.find((img) => img.name === 'Skill-01'),
      skillsData: hireFromCrioData,
      badge: {
        src: 'Crio-Certified-Badge.png',
        alt: 'Certified Badge',
        class: 'certified-badge',
        style: {
          position: 'absolute',
          zIndex: 2,
          top: '-70px',
          right: '-75px',
          width: '36px',
          height: '36px',
          transform: 'rotate(12deg)',
        },
      },
    },
    {
      title: 'Strong low-level systems understanding',
      description: `Our <strong>Low Level design</strong> exercises equip developers to create clean solutions using Object Modeling, SOLID Principles and Design patterns, do API and Schema design. <strong>High Level design</strong> is mastered by working on a scale-able distributed MicroServices based application with components like MySQL, Mongo, Kafka, Redis etc., on the cloud.`,
      imgName: skillImagesData.find((img) => img.name === 'Skill-02'),
    },
    {
      title: 'Good Data Structure, Algorithms knowledge',
      description: `Through curated problem statements, mini-projects, and a rigorous study plan, our developers strengthen their Data Structures, Algorithms, and problem solving skills.`,
      imgName: skillImagesData.find((img) => img.name === 'Skill-03'),
    },
  ];

  return (
    <section className="flex w-full items-center justify-center bg-crio-neutral-75 p-10 text-center text-crio-neutral-900">
      <div className="h-full w-full">
        <h1 className="font-dmSans md:text-5xl">Hire for skills you need</h1>
        <h2 className="my-4 text-base text-lg font-normal leading-loose">
          Here is what you can expect from a{' '}
          <strong>Crio Certified Developer</strong>.
        </h2>
        <div className="my-12 flex justify-center">
          <div className="flex w-full flex-col gap-6 md:w-11/12">
            {skillsData.map((skill, index) => (
              <SkillCard
                key={index}
                title={skill.title}
                description={skill.description}
                imgName={skill.imgName}
                skillsData={skill.skillsData}
                reverse={index % 2 === 0}
                index={index}
                badge={skill.badge}
              />
            ))}
          </div>
        </div>
        <div className=" my-4 flex w-full items-center justify-center md:justify-start">
          <div className="flex w-1/2 flex-col items-center justify-center md:w-full">
            <RequestCallbackButton scrollToTop={true} />

            <label className="mt-2 text-sm text-crio-neutral-800 md:text-xs">
              Submit your requirements & our team will get in touch
            </label>
          </div>
        </div>
      </div>
    </section>
  );
}
