import { HIRE_FROM_CRIO_LSQ_HOST } from '../src/constants/hire-from-crio/index';

const CREATE_LEAD_URL = `${HIRE_FROM_CRIO_LSQ_HOST}/LeadManagement.svc/Lead.CreateOrUpdate`;

const UPDATE_LEAD_BY_LEAD_ID = `${HIRE_FROM_CRIO_LSQ_HOST}/LeadManagement.svc/Lead.Update?leadId=`;

const RETRIEVE_LEAD_BY_EMAIL_URL = `${HIRE_FROM_CRIO_LSQ_HOST}/LeadManagement.svc/Leads.GetByEmailaddress?&emailaddress=`;

const RETRIEVE_LEAD_BY_PHONE = `${HIRE_FROM_CRIO_LSQ_HOST}/LeadManagement.svc/RetrieveLeadByPhoneNumber?phone=`;

const CREATE_ACTIVITY_UPON_LEAD_GENERATION_URL = `${HIRE_FROM_CRIO_LSQ_HOST}/ProspectActivity.svc/Create`;

export const generateLead = async (lead) => {
  const response = await fetch(CREATE_LEAD_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(lead),
  });

  // Check if the response is not OK
  if (!response.ok) {
    // Read and log the error response body
    const errorBody = await response.text();
    console.log('Error response body:', errorBody);

    throw new Error(errorBody);
  }

  // Read and log the response body
  const data = await response.json();

  return data;
};

export const retrieveLeadByPhone = async (phone) => {
  const response = await fetch(`${RETRIEVE_LEAD_BY_PHONE}${phone}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch lead by phone number', response);
  }

  const data = await response.json();
  return data;
};

export const retrieveLeadByEmail = async (email) => {
  const response = await fetch(`${RETRIEVE_LEAD_BY_EMAIL_URL}${email}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Network response was not ok ', response);
  }

  const data = await response.json();
  return data;
};

export const updateLeadByLeadId = async (leadId, updatedData) => {
  const response = await fetch(`${UPDATE_LEAD_BY_LEAD_ID}${leadId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(updatedData),
  });

  if (!response.ok) {
    throw new Error('Failed to update lead by lead Id', response);
  }

  const data = await response.json();

  return data;
};

export const createActivityUponLeadGen = async (activityData) => {
  const response = await fetch(CREATE_ACTIVITY_UPON_LEAD_GENERATION_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(activityData),
  });

  if (!response.ok) {
    throw new Error('Failed to create lead', response.statusText);
  }

  const data = await response.json();
  return data;
};
