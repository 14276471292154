import React from 'react';

export default function TechList({ title, skills }) {
  return (
    <div className="w-1/3">
      <h3 className="text-xl font-semibold">{title}</h3>
      <ul className="list-none pl-0 leading-9">
        {skills.map((skill) => (
          <li className="text-[#6d6d6d] " key={skill}>
            {skill}
          </li>
        ))}
        <li className="text-[#6d6d6d]">and more...</li>
      </ul>
    </div>
  );
}
