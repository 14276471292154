import React from 'react';
import { MenuItem, TextField } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import FormIcon from './FormIcon';

export default function FormDropDown({
  name,
  menu_items,
  icon,
  label,
  isOpenForm,
  handleFocus,
}) {
  const formContext = useFormContext();
  const error = formContext.formState.errors[name]?.message;

  return (
    <Controller
      name={name}
      render={({ field: { onChange, onBlur, value, ref } }) => {
        return (
          <TextField
            value={value}
            select
            variant="filled"
            defaultValue=""
            onFocus={handleFocus}
            name={name}
            label={label}
            helperText={error}
            onBlur={onBlur}
            ref={ref}
            onChange={onChange}
            fullWidth
            InputProps={{
              disableUnderline: true,
              style: {
                color: '#7A8B87',
                borderRadius: '1rem',
                padding: 3,
              },
            }}
            InputLabelProps={{
              style: {
                ...(error ? { color: '#F3736A' } : { color: '#7A8B87' }),
              },
            }}
            FormHelperTextProps={{
              style: {
                color: '#F3736A',
              },
            }}
            className={`${
              error ? 'bg-v5-red-500' : 'bg-crio-neutral-50'
            } text-[#] rounded-lg`}
            inputProps={{
              'aria-label': `Enter ${label}`,
            }}
            SelectProps={{
              IconComponent: () => (
                <FormIcon
                  icon={icon}
                  error={error}
                  validated={
                    formContext.formState.dirtyFields[name] &&
                    !formContext.formState.errors[name]
                  }
                />
              ),
              MenuProps: {
                PaperProps: {
                  style: {
                    borderBottomRightRadius: 16,
                    borderBottomLeftRadius: 16,
                    maxHeight: '140px',
                    borderBottom: '12px',
                    marginTop: 1,
                    zIndex: 1,
                  },
                  sx: {
                    bgcolor: 'crio-neutral-50',
                    color: '#7A8B87',
                    '&::-webkit-scrollbar': {
                      width: 24,
                    },
                    '&::-webkit-scrollbar-track': {
                      backgroundColor: '#fff',
                      borderRadius: '12px',
                      border: `2px solid #7A8B87`,
                      backgroundClip: 'content-box',
                      marginRight: '4px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: 'crio-neutral-50',
                      borderRadius: 1000,
                      border: '2px solid #7A8B87',
                      height: 24,
                      width: 18,
                    },
                  },
                },
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              },
            }}
          >
            {menu_items.map((option) => (
              <MenuItem key={option.label} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        );
      }}
    ></Controller>
  );
}
