import React from 'react';
import Button from '@components/Button/index';
import CircularProgress from '@mui/material/CircularProgress';
import scrollTo from 'gatsby-plugin-smoothscroll';

const RequestCallbackButton = ({
  scrollToTop,
  onClick,
  className,
  loading,
  ...props
}) => {
  const handleClick = () => {
    if (scrollToTop) {
      scrollTo('#hireFromCrio');
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <Button
      className={`${className} shimmer-btn w-64 bg-crio-yellow-500 p-4 text-crio-green-900`}
      onClick={handleClick}
      disabled={loading}
      {...props}
    >
      {loading ? (
        <CircularProgress size={24} color="inherit" />
      ) : (
        'Request Callback'
      )}
    </Button>
  );
};

export default RequestCallbackButton;
