import React from 'react';
import Testimonials from '@components/HireFromCrio/Testimonials/index';
import hireFromCrioData from '@src/PageData/hireFromCrio.json';
import RequestCallbackButton from '../RequestCallbackButton/index';

export default function CompanyTestimonialsSection() {
  return (
    <section
      className="flex w-full items-center justify-center bg-crio-neutral-75 text-center text-crio-neutral-900 md:p-10"
      style={{ minHeight: '700px' }}
    >
      <div className="h-full w-full">
        <div>
          <Testimonials data={hireFromCrioData?.companyTestimonials} />
        </div>
        <div className="my-4 flex w-full items-center justify-center md:justify-start">
          <div className="flex w-1/2 flex-col items-center justify-center md:w-full">
            <RequestCallbackButton scrollToTop={true} />

            <label className="mt-2 text-sm md:text-xs">
              Submit your requirements & our team will get in touch
            </label>
          </div>
        </div>
        <div className="my-16 grid gap-10 md:grid-cols-3 md:gap-2">
          <div className="flex flex-col items-center justify-center">
            <h3
              className={`bg-clip-text font-dmSans text-9xl text-transparent md:text-9xl`}
              style={{
                background:
                  'linear-gradient(268.38deg, #45B680 11.19%, #A7DF67 44.32%)',
              }}
            >
              80%
            </h3>
            <p>
              <strong>reduction</strong> in hiring cycle
            </p>
          </div>
          <div>
            <h3
              className={`bg-clip-text font-dmSans text-9xl text-transparent md:text-9xl`}
              style={{
                background:
                  'linear-gradient(268.38deg, #45B680 11.19%, #A7DF67 44.32%)',
              }}
            >
              35%
            </h3>
            <p>
              <strong>higher</strong> conversion rate
            </p>
          </div>
          <div>
            <h3
              className={`bg-clip-text font-dmSans text-9xl text-transparent md:text-9xl`}
              style={{
                background:
                  'linear-gradient(268.38deg, #45B680 11.19%, #A7DF67 44.32%)',
              }}
            >
              ₹0
            </h3>
            <p>hiring fees</p>
          </div>
        </div>
      </div>
    </section>
  );
}
