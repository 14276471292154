import React from 'react';
import * as styles from './faq.module.scss';
import RawHTML from '../RawHTML/index';

export default function FAQs(props) {
  return (
    <div>
      <div className="col-12 flex flex-col items-center justify-center p-0 text-left">
        <div className="container   p-0 " style={{ marginTop: '-30px' }}>
          <div className={`${styles.accordion} custom z-10 mb-4`}>
            <ul>
              {props.faqData?.map((data, idx) => (
                <li key={idx}>
                  <input
                    type="checkbox"
                    defaultChecked
                    className={idx == 1 ? 'min-ctc' : ''}
                  />
                  <i />
                  <div className={styles.heading}>{data.title}</div>
                  <p className={styles.description}>
                    <div>
                      <ul>
                        <li>
                          <RawHTML>{data.description}</RawHTML>
                        </li>
                      </ul>
                    </div>
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
