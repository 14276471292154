import React from 'react';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import hireFromCrioData from '@src/PageData/hireFromCrio.json';
import RequestCallbackButton from '../RequestCallbackButton/index';

export default function CompaniesSection({ type, typeform }) {
  const data = useStaticQuery(graphql`
    query {
      companyImages: allFile(
        filter: {
          relativePath: { regex: "images/recruit/Company-Logos/Green/" }
        }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              blurredOptions: {}
              width: 300
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  `);

  const companyImagesData = data.companyImages.nodes;

  return (
    <section className="flex w-full items-center justify-center bg-crio-neutral-100 p-10 text-center text-crio-neutral-900">
      <div className="h-full w-full">
        <h1 className="text-3xl text-crio-neutral-900">
          Tech companies that trust our talent
        </h1>
        <div className="my-12">
          <div className="flex items-center justify-center">
            <div className="overflow-scroll md:overflow-hidden">
              <div
                className="mx-auto grid grid-cols-5 items-center justify-center gap-2 md:gap-x-8 md:gap-y-4"
                style={{ width: '800px' }}
              >
                {hireFromCrioData?.companyImages.map((companyImage) => {
                  const companyImageSource = companyImagesData.find(
                    (ele) => ele.name === companyImage.imgUrl,
                  );
                  const image = getImage(companyImageSource.childImageSharp);

                  return (
                    <div
                      className={`mx-auto w-28 ${
                        companyImage.title === 'Jio'
                          ? 'mr-12 w-20 justify-self-center md:mr-0'
                          : ''
                      }`}
                      key={companyImage.title}
                    >
                      <GatsbyImage
                        loading="lazy"
                        image={image}
                        placeholder="blurred"
                        alt={'Company'}
                        className="w-full grayscale hover:grayscale-0"
                        imgStyle={{ objectFit: 'contain' }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <p>and many more...</p>
        </div>
        <div className="my-4 flex w-full items-center justify-center md:justify-start">
          <div className="flex w-1/2 flex-col items-center justify-center md:w-full">
            <RequestCallbackButton scrollToTop={true} />
            <label className="mt-2 text-sm text-crio-neutral-800 md:text-xs">
              Submit your requirements & our team will get in touch
            </label>
          </div>
        </div>
      </div>
    </section>
  );
}
